import { ENVIRONMENT_QUAL } from './qual';

export const environment = {
  ...ENVIRONMENT_QUAL,
  apiServer: 'https://kolkata.app.1life.com',
  myoneServer: 'https://kolkata.app.1life.com',
  sentry: {
    enabled: true,
    dsn: 'https://94fb2cb964527591835f763ad4493a4f@o4506221115408384.ingest.us.sentry.io/4506855554154496',
    environment: 'onelife-kolkata',
  },
  stripePublishableKey:
    'pk_test_51O3RX3ATY2u5CNNKMnp9ATcj8a3LTcr7edcxgOReEA7a8vuGIybaHScf0hf3PzrHTLMHNMethqOmjFUa6nWY6AO900IS4lhtmU',
  launchDarklyClientId: '605033d09a881a0c380e4abc',
};
