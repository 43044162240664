import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { AttemptedPathService } from '@app/core/attempted-path.service';
import { LinksService } from '@app/core/links.service';
import { RegistrationErrorCodes } from '@app/registration/registration-error/registration-error.component';

import { AuthService } from './auth.service';

export const UNAUTHORIZED_ERROR = 'unauthorized';
export const USER_BLOCKED_ERROR_DESCRIPTION = 'user is blocked';

@Injectable({
  providedIn: 'root',
})
export class Auth0ErrorGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private linksService: LinksService,
    private attemptedPathService: AttemptedPathService,
  ) {}

  private readonly USER_IS_BLOCKED_ERROR_CODE = 'userIsBlocked';

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const { error_description: rawErrorDescription, error } = route.queryParams;

    if (!rawErrorDescription) {
      return true;
    }

    let errorCode: string;

    type CustomAuth0ErrorDescription = Record<'claim_code' | 'errorCode', string>;
    let customAuth0ErrorDescription: CustomAuth0ErrorDescription = <CustomAuth0ErrorDescription>{};

    try {
      customAuth0ErrorDescription = JSON.parse(decodeURIComponent(rawErrorDescription));
      errorCode = customAuth0ErrorDescription.errorCode;
    } catch (e) {
      if (error === UNAUTHORIZED_ERROR && rawErrorDescription === USER_BLOCKED_ERROR_DESCRIPTION) {
        errorCode = this.USER_IS_BLOCKED_ERROR_CODE;
      } else {
        throw e;
      }
    }

    let queryParams: string;

    switch (errorCode) {
      case RegistrationErrorCodes.LoginWithAmazonEnrollmentDefault:
        const { claim_code: claimCode } = customAuth0ErrorDescription;
        queryParams = new URLSearchParams({
          claim_code: claimCode,
          errorCode,
        }).toString();

        this.authService.logout(`${this.linksService.registrationError}?${queryParams}`);
        break;
      case RegistrationErrorCodes.DuplicateExternalId:
        queryParams = new URLSearchParams({
          errorCode,
        }).toString();

        this.authService.logout(`${this.linksService.registrationError}?${queryParams}`);
        break;
      case 'userIsBlocked':
        const { url } = state;
        this.attemptedPathService.setAttemptedPath(state.url);
        this.authService.goLogin({
          path: url,
          customAuthorizationParams: { user_blocked: 'true' },
        });

        break;
      default:
        throw new Error(`Unknown error code: ${errorCode}`);
    }

    return false;
  }
}
