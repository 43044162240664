import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { MembershipAction, MembershipService } from '@app/core/membership.service';
import { MembershipUpdateToHomepageEdge } from '@app/registration/graph/edges/membership-update-to-homepage.edge';
import { MembershipUpdateToPrepaidConfirmationEdge } from '@app/registration/graph/edges/membership-update-to-prepaid-confirmation.edge';
import { MembershipUpdateToPrepaidInviteEdge } from '@app/registration/graph/edges/membership-update-to-prepaid-invite.edge';
import { NodeUrlMappingService } from '@app/registration/graph/node-url-mapping.service';
import { RegistrationNode } from '@app/registration/graph/nodes/registration.node';
import { MembershipStatusGraphqlService } from '@app/registration/membership-status-graphql.service';
import { ClaimCodeValidationError, PrepaidEnrollmentService } from '@app/registration/prepaid-enrollment.service';

@Injectable({
  providedIn: 'root',
})
export class MembershipUpdateNode extends RegistrationNode {
  constructor(
    private membershipService: MembershipService,
    private membershipUpdateToHomepageEdge: MembershipUpdateToHomepageEdge,
    private membershipUpdateToPrepaidConfirmationEdge: MembershipUpdateToPrepaidConfirmationEdge,
    private membershipUpdateToPrepaidInviteEdge: MembershipUpdateToPrepaidInviteEdge,
    private membershipStatusGraphqlService: MembershipStatusGraphqlService,
    private prepaidEnrollmentService: PrepaidEnrollmentService,
    nodeUrlMappingService: NodeUrlMappingService,
    router: Router,
  ) {
    super(nodeUrlMappingService, router);
    this.edges = [
      membershipUpdateToPrepaidInviteEdge,
      membershipUpdateToPrepaidConfirmationEdge,
      membershipUpdateToHomepageEdge,
    ];
    this.isAsync = true;
  }

  execute$({ claimCode }: { claimCode?: string } = {}): Observable<void> {
    return this.membershipService
      .createOrRenewConsumerOrAmazonMembership({
        claimCode,
        membershipAction: MembershipAction.New,
      })
      .pipe(
        map(() => undefined),
        catchError(error =>
          this.prepaidEnrollmentService
            .getErrorRoute$({
              error: new ClaimCodeValidationError(error),
              source: 'Login with Amazon',
              claimCode,
            })
            .pipe(
              map(errorUrl => {
                this.router.navigateByUrl(errorUrl);
              }),
            ),
        ),
        switchMap(() =>
          this.membershipStatusGraphqlService.fetch().pipe(
            filter(result => !!result.data?.membership?.isActive),
            map(() => undefined),
          ),
        ),
      );
  }
}
